import axios from "axios";

const axiosInstance = axios.create({

  // Base URL Or QE Gold
  baseURL: "https://swsqe10.softwisesol.com",
    //  baseURL: "https://qegoldbackenddemo.softwisesol.com",
// 
  // Base URL Or POS Demo
  // baseURL: "https://swsposdemo01.softwisesol.com",
 


  // Base URL Or QE Prime
  // baseURL: 'https://swsqe11.softwisesol.com',
    //  baseURL: "https://qeprimebackenddemo.softwisesol.com",


    // Base URL Or The Woods
  // baseURL: 'https://thewoodsbackend.softwisesol.com',
  //  baseURL: "https://testbackend.softwisesol.com",
  
  /* The line `const COMPANYHEADER = "Qureshi Electronics"` is declaring a constant variable named
  `COMPANYHEADER` and assigning it the string value "Qureshi Electronics". This constant represents
  the name of a company or business entity in the code. */
  // baseURL: 'http://localhost:4500',
  withCredentials: true,
});
export default axiosInstance;

