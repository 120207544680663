export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"

export const LOGOUT_FAIL ="LOGOUT_FAIL"
export const LOGOUT_REQUEST= "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS= "LOGOUT_SUCCESS"


export const GET_USERS_ON_SHOPCODE_REQUEST ="GET_USERS_ON_SHOPCODE_REQUEST"
export const GET_USERS_ON_SHOPCODE_SUCCESS= "GET_USERS_ON_SHOPCODE_SUCCESS"
export const GET_USERS_ON_SHOPCODE_FAIL= "GET_USERS_ON_SHOPCODE_FAIL"

export const FORGOTPASSWORD_FAIL ="FORGOTPASSWORD_FAIL"
export const FORGOTPASSWORD_REQUEST= "FORGOTPASSWORD_REQUEST"
export const FORGOTPASSWORD_SUCCESS= "FORGOTPASSWORD_SUCCESS"

export const TOKEN_REFRESH_REQUEST = "TOKEN_REFRESH_REQUEST"
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL"
export const TOKEN_REFRESH_SUCCESS= "TOKEN_REFRESH_SUCCESS"


export const DEVICE_ID_REQUEST = "DEVICE_ID_REQUEST"
export const SET_DEVICE_ID = "SET_DEVICE_ID"
export const CLEAR_DEVICE_ID = "CLEAR_DEVICE_ID"

export const CLEAR_ERRORS = "CLEAR_ERRORS"